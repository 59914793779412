export interface IMarker {
    id: string
    type: string,
    subtype: string,
    description: string,
    status: string,
    position: google.maps.LatLngLiteral,
    options: google.maps.MarkerOptions,
    vehicles: IVehicle[],
    victims: IVictims[],
    agent: string [],
}

export interface IMarkerDevices {
  id: string;
  type: string;
  subtype: string;
  position: google.maps.LatLngLiteral;
  agent: AgentMarkerDevice[];
  options: google.maps.MarkerOptions;
  subType: string;
}

export interface AgentMarkerDevice {
  badge_number: string;
  degree?: string;
  names?: string;
  surnames?: string;
  id?: string;
}

export interface IVehicle {
  plate_type: number,
  plate: string,
  plateImage?: string,
}

export interface IVictims {
  identification_type: string,
  identification_number: string
}

export interface IMarkerService { // Cambiar nombre
  type: string,
  subtype: string,
  status: string,
  description: string,
  latitude: number,
  longitude: number,
  id: string,
  vehicles: IVehicle[],
  victims: IVictims[]
  agent: string[],
  reportDate: string
}

export interface IMarkerDevicesService {
  id: string;
  type: string;
  latitude: number;
  longitude: number;
  agent: string;
}