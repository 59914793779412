import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

import {IMarkerDevicesService, IMarkerService} from '@shared/interfaces/map.interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { FiltersPinsMap, LoadApiMap } from './interface/loadApiMap.interface';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  public loadMap = new BehaviorSubject<LoadApiMap>({isLoad: false, error: false, message: null});

  private filtersPinSubject$: BehaviorSubject<FiltersPinsMap> = new BehaviorSubject<FiltersPinsMap>({typeDevices: null, incidentId: null, showCreateIncident: true});
  public filtersPin$: Observable<FiltersPinsMap> = this.filtersPinSubject$.asObservable();



  constructor(private httpClient: HttpClient) { }
  public getMarkersNew(): Observable<IMarkerService[]> {
    return this.httpClient.get<IMarkerService[]>(`${environment.TRACRBACK_URL}/gps`)
  }

  public getMarkersType(type: string): Observable<IMarkerService[]> {
    return this.httpClient.get<IMarkerService[]>(`${environment.TRACRBACK_URL}/gps?type=${type}`)
  }

  public getMarkerById(id: string, type: string): Observable<IMarkerService> {
    return this.httpClient.get<IMarkerService>(`${environment.TRACRBACK_URL}/gpsById?type=${type}&id=${id}`)
  }

  public getFiltersPin(): Observable<FiltersPinsMap> {
    return this.filtersPin$;
  }

  public setFiltersPin(filters: FiltersPinsMap) {
    this.filtersPinSubject$.next(filters);
  }
}
